import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"

const liStyle = {
  display: "list-item",
  listStyleType: "disc",
  paddingBottom: ".5rem",
  marginLeft: "2rem",
  fontSize: "14px",
  color: "#777575",
}
const Privacy = () => {
  return (
    <Layout>
      <Head
        title="Privacy"
        description="ThrowThings.com, LLC Provacy Policy information can be found here."
      />
      <Container>
        <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="banner-section">
                  <img
                    src={require("../assets/images/about/about-us.jpg")}
                    className="img-fluid blur-up lazyload"
                    alt=""
                  />
                </div> */}
              </div>
              <div className="col-sm-12">
                <h4>ThrowThings.com, LLC's Privacy Policy</h4>
                <p>
                  Information about our customers is an important part of our
                  business, and we are not in the business of selling it to
                  others. We share customer information only as described below,
                  as needed to process and complete your order, and with
                  subsidiaries ThrowThings.com, LLC controls that either are
                  subject to this Privacy Notice or follow practices at least as
                  protective as those described in this Privacy Notice.
                </p>
                <ul>
                  <li style={liStyle}>
                    <strong>Business Transfers:</strong> As we continue to
                    develop our business, we might sell or buy stores,
                    subsidiaries, or business units. In such transactions,
                    customer information generally is one of the transferred
                    business assets but remains subject to the promises made in
                    any pre-existing Privacy Notice (unless, of course, the
                    customer consents otherwise). Also, in the unlikely event
                    that ThrowThings.com, LLC, or substantially all of its
                    assets are acquired, customer information will of course be
                    one of the transferred assets.
                  </li>
                  <li style={liStyle}>
                    <strong>Protection of ThrowThings.com and Others:</strong>{" "}
                    We release account and other personal information when we
                    believe release is appropriate to comply with the law;
                    enforce or protect the rights, property, or safety of
                    ThrowThings.com, our users, or others. This includes
                    exchanging information with other companies and
                    organizations for fraud protection and credit risk
                    reduction. Obviously, however, this does not include
                    selling, renting, sharing, or otherwise disclosing
                    personally identifiable information from customers for
                    commercial purposes in violation of the commitments set
                    forth in this Privacy Notice.
                  </li>
                  <li style={liStyle}>
                    <strong>Past/Present Client Information:</strong> We may use
                    the names, individually or as a part of a listing, of
                    companies and/or organizations who have ordered from us or
                    on whose behalf an order was placed. (This includes orders
                    of custom imprinted products which were for the use of a
                    company or organization or used a company or organization
                    logo). When products are ordered for use in motion picture,
                    television, or live stage performance we may use the name of
                    the company/organization that placed the order and/or the
                    motion picture/television show/stage performance that the
                    product was ordered for. We use these names as examples of
                    past and present clients and to let the public and potential
                    customers know where our products are being used.
                  </li>
                  <li style={liStyle}>
                    <strong>With Your Consent:</strong> Other than as set out
                    above, you will receive notice when information about you
                    might go to third parties, and you will have an opportunity
                    to choose not to share the information.
                  </li>
                </ul>
                <p>
                  Should we find it necessary to make any substantial changes in
                  the way we use your personal information we will notify you by
                  posting a prominent announcement on our site for a period of
                  time to be determined by ThrowThings.com, LLC. pages.
                </p>
                <p>
                  ThrowThings.com, LLC reserves the right to amend this Privacy
                  Policy from time to time.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default Privacy
