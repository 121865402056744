import React from "react"
// import React, { useState } from "react"
import { Link } from "gatsby"
import {
  // Collapse,
  // Navbar,
  // NavbarToggler,
  // NavbarBrand,
  // Nav,
  // NavItem,
  // NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // NavbarText
} from "reactstrap"
import * as layoutStyles from "./header.module.scss"

const HeaderNav = props => {
  // const [collapsed, setCollapsed] = useState(true);

  // const toggleNavbar = () => setCollapsed(!collapsed);

  // const burgerStyle = {
  // color: "red",
  // }
  return (
    <div>
      {/* <Navbar color="faded" light>
        <i className="fa fa-bars sidebar-bar mr-2" onClick={toggleNavbar} style={burgerStyle} ></i>
        <Collapse isOpen={!collapsed} navbar>

        <ul id="main-menu" className="sm pixelstrap sm-horizontal">
      <li>
        <Link to="#">Home</Link>
      </li>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          departments
        </DropdownToggle>
        <DropdownMenu right className={layoutStyles.navLink}>
          <DropdownItem>
            <Link to="#">
              <div>
                <strong>Throw Your Name Around</strong>
              </div>
              <div>Custom Printed</div>
              <div>Promotional Products</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw Your Voice</strong>
              </div>
              <div>Ventriloquist Dummies, Puppets</div>
              <div> & Marionettes</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Things To Throw</strong>
              </div>
              <div>Sports, Games, Toys & More</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throwbacks</strong>
              </div>
              <div>Classic Toys, Games & Gags</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw Up</strong>
              </div>
              <div>Fake Vomit & Other Gross Stuff</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw A Party</strong>
              </div>
              <div>Party Supplies & Costumes</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw Your Money Away</strong>
              </div>
              <div>We Make It Easy</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>ThrowThings Things</strong>
              </div>
              <div>You ThrowThings - Tell The World!</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw It On The Barbie</strong>
              </div>
              <div>Portable Grills & More</div>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/about">
              <div>
                <strong>Throw Things Away</strong>
                <span className="new-tag">new </span>
              </div>
              <div>Coming Soon!</div>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <li>
        <Link to="/about">about us</Link>
      </li>
      <li>
        <Link to="returns">returns</Link>
      </li>
      <li>
        <Link to="shipping">shipping</Link>
      </li>
      <li>
        <Link to="contact">contact us</Link>
      </li>
    </ul>
               </Collapse>
                </Navbar>
 */}

      <ul id="main-menu" className="sm pixelstrap sm-horizontal">
        <li>
          <Link to="/">Home</Link>
        </li>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            departments
          </DropdownToggle>
          <DropdownMenu right className={layoutStyles.navLink}>
            <DropdownItem>
              <a href="https://www.throwthings.com/promotional/">
                <div>
                  <strong>Throw Your Name Around</strong>
                </div>
                <div>Custom Printed</div>
                <div>Promotional Products</div>
              </a>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-your-voice">
                <div>
                  <strong>Throw Your Voice</strong>
                </div>
                <div>Ventriloquist Dummies, Puppets</div>
                <div> & Marionettes</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/tt-things-to-throw">
                <div>
                  <strong>Things To Throw</strong>
                </div>
                <div>Sports, Games, Toys & More</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throwbacks">
                <div>
                  <strong>Throwbacks</strong>
                </div>
                <div>Classic Toys, Games & Gags</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-up">
                <div>
                  <strong>Throw Up</strong>
                </div>
                <div>Fake Vomit & Other Gross Stuff</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-a-party">
                <div>
                  <strong>Throw A Party</strong>
                </div>
                <div>Party Supplies & Costumes</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-your-money-away">
                <div>
                  <strong>Throw Your Money Away</strong>
                </div>
                <div>We Make It Easy</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throwthings-things">
                <div>
                  <strong>ThrowThings Things</strong>
                </div>
                <div>You ThrowThings - Tell The World!</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-it-on-the-barbie">
                <div>
                  <strong>Throw It On The Barbie</strong>
                </div>
                <div>Portable Grills & More</div>
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link to="/throw-things-away">
                <div>
                  <strong>Throw Things Away</strong>
                  <span className="new-tag">new </span>
                </div>
                <div>Coming Soon!</div>
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <li>
          <Link to="/about">about us</Link>
        </li>
        <li>
          <Link to="/returns">returns</Link>
        </li>
        <li>
          <Link to="/shipping">shipping</Link>
        </li>
        <li>
          <Link to="/contact">contact us</Link>
        </li>
      </ul>
    </div>
  )
}

export default HeaderNav
